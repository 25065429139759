// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {setSession} from "../redux/auth/saga";

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        setSession(null);
        return false;
    } else {
        return true;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {

    const cookies = new Cookies();
    const user = cookies.get('user', { path: '/' });
    const companyData = cookies.get('company', { path: '/' });

    for(var property in companyData){
        if (companyData.hasOwnProperty(property)) {
            user[property] = companyData[property];
        }
    }

    if (user) {
        if (user.colorMain) { document.documentElement.style.setProperty('--color1',user.colorMain); }
        if (user.colorSecondary) { document.documentElement.style.setProperty('--color2',user.colorSecondary); }
        if (user.colorBackground) { document.documentElement.style.setProperty('--color3',user.colorBackground); }
        if (user.colorComplementary) { document.documentElement.style.setProperty('--color4',user.colorComplementary); }
    }

    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const updateUserField = (field,value) => {

    const cookies = new Cookies();
    const user = cookies.get('user');
    user[field] = value;
    setSession(user);

}

const updateCompanyTheme = (values) => {

    const cookies = new Cookies();
    const user = cookies.get('user');

    user.colorMain = values.colorMain;
    user.colorSecondary = values.colorSecondary;
    user.colorComplementary = values.colorComplementary;
    user.colorBackground = values.colorBackground;

    cookies.set('user', user);
}

const isWorkerDisabled = (loggedInUser) => {
    return (
        loggedInUser.isActive !== 1 || loggedInUser.isDeleted === 1 || loggedInUser.isUnsubscribed
    );
};

const isWorkerDisabledPath = (path) => {
    let paths = ['/','/dashboard','/cards','/user/profile','/user/password','/account/logout'];
    return paths.indexOf(path) !== -1;
};

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <button onClick={() => loginWithRedirect()}>Log In</button>;
};

const LiveLoginButton = () => {
    return <a className={'btn btn-primary'} href={'https://dev-kf34fjcd6n7ivpu4.eu.auth0.com/samlp/evi0ZOM6DaUHdkKqzaN8lYeH08JLADmM?connection=windowslive'}
              >Log In Live</a>;
};

const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
            Log Out
        </button>
    );
};

const Profile = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (!isAuthenticated) {
        return <div>NO</div>;
    }

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    return (
        isAuthenticated && (
            <div>
                <img src={user.picture} alt={user.name} />
                <h2>{user.name}</h2>
                <p>{user.email}</p>
            </div>
        )
    );
};

export { isUserAuthenticated, getLoggedInUser, isWorkerDisabled, isWorkerDisabledPath, updateCompanyTheme,
    LiveLoginButton, LoginButton, LogoutButton, Profile,
    updateUserField };
